import { Component, OnInit, Output, OnChanges, Input, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GasStationService } from 'src/app/services/gas-station/gas-station.service';
import { ToastrService } from 'ngx-toastr';
import { ConvertersService } from 'src/app/services/helpers/converters.helpers';
import * as moment from 'moment'
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cta-form-gas-station',
  templateUrl: './form-gas-station.component.html',
  styleUrls: ['./form-gas-station.component.scss']
})
export class FormGasStationComponent implements OnInit, OnChanges {

  cadastrar: FormGroup;

  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  isEdit: boolean;
  dataInjected: any;

  @Output() validForm = new EventEmitter()

  client: number;

  constructor(
    private fb: FormBuilder,
    private gasStationService: GasStationService,
    private toastService: ToastrService,
    private convertService: ConvertersService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    const format_date = moment().format('d/m/Y H:M:S');
    const last_update = this.convertService.convertUtc(format_date);
    this.cadastrar = this.fb.group({
      id: this.fb.control(0),
      name: this.fb.control('', [Validators.required]),
      address: this.fb.control('', [Validators.required]),
      neighborhood: this.fb.control('', [Validators.required]),
      city: this.fb.control('', [Validators.required]),
      state: this.fb.control('', [Validators.required]),
      phone: this.fb.control('', [Validators.required]),
      liaison: this.fb.control(''),
      note: this.fb.control(''),
      is_comercial: false,
      client: this.fb.control('', [Validators.required])
    })
    if(data){
      this.dataInjected = data;
      this.isEdit = data.function === 'Editar posto'
    }
  }


  ngOnInit() {
    if(this.dataInjected && this.dataInjected.values && this.dataInjected.values !== null){

      const value = this.dataInjected.values[0]
      this.cadastrar.setValue({
        id: value && value.id ? value.id : null,
        name: value.name,
        address: value.address,
        neighborhood: value.neighborhood,
        city: value.city,
        state: value.state,
        phone: value.phone,
        liaison: value.liaison,
        note: value.note,
        is_comercial: value.is_comercial,
        client: value.client.id

      })
    }
  }

  submitEmmiterButton = event => {
    if (event) {
      this.validForm.emit(event);
    }
  }



  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar.valid && !this.isEdit) {
        this.createGasStation();
      }else{
        this.editGasStation()
      }
    }
  }


  createGasStation = () => {
    this.gasStationService.createGasStation(this.cadastrar.value).subscribe(
      res => {
        localStorage.setItem('lastSaveGasStation', res.results[0].id)
        this.toastService.success("Posto cadastrado com sucesso")
      },
      err => {
        console.log('err', err);
        this.toastService.error("Ops! Ocorreu uma falha ao tentar salvar o posto")
      }
    )
  }

  editGasStation = () => {
    if(this.cadastrar.value.id){
      this.gasStationService.editGasStation(this.cadastrar.value)
      .subscribe(
        res => {
          localStorage.setItem('lastSaveGasStation', res.results[0].id)
          this.toastService.success("Posto editado com sucesso")
        },
        err => {
          console.log('err', err);
          this.toastService.error("Ops! Ocorreu uma falha ao tentar editar o posto")
        }
      )
    }
  }

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }

  selectedEvents = (event, value) => {
    if (event == 'client') {
      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        name: this.cadastrar.value.name,
        address: this.cadastrar.value.address,
        neighborhood: this.cadastrar.value.neighborhood,
        city: this.cadastrar.value.city,
        state: this.cadastrar.value.state,
        phone: this.cadastrar.value.phone,
        liaison: this.cadastrar.value.liaison,
        note: this.cadastrar.value.note,
        is_comercial: this.cadastrar.value.is_comercial,
        client: value
      })

    }
  }
}
