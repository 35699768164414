import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import {URLS_DEFAULT} from '../configs/urls_default';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string, tokenRest: string) {

        const headerOptions = { headers: new HttpHeaders(
            {
                'Content-Type':'application/json',
                'Authorization':`bearer ${tokenRest}`,
                'Access-Control-Allow-Origin': 'http://localhost:4200'
            }
        )};
        const url = `${URLS_DEFAULT.BASE_URL}session?login=${username}&password=${password}`;
        return this.http.get<any>(url, headerOptions)
            .pipe( map(user => {
                if (user && user.results[0]['user_token']) {
                    sessionStorage.setItem('currentUser', JSON.stringify(user.results));
                    const token = user.results[0]['user_token'];
                    const userLogin = user.results[0]['login'];
                    sessionStorage.setItem('tokenUser', token);
                    localStorage.setItem('userLogin', userLogin);
                    if(sessionStorage.getItem('tokenUser') !== null && sessionStorage.getItem('tokenUser') !== undefined){
                        this.currentUserSubject.next(user);
                    }
                }

                return user;
        }));
    }

    logout = () => {
        sessionStorage.clear();
        localStorage.clear()
        this.currentUserSubject.next(null);
    }

    generatorTokenRest(){
        // const tokenRest = 'GEBGxSrIWd5TwwivevYPJFAxaib1nkCtak8l/IrjI2UB1wqmCZ+7VLA0O0T0KhpD'; 
        const tokenRest = !URLS_DEFAULT.BUILD_PROD ? 'PSVlcWQNItw9FYeRuoc28pqisUZaPdyrDnXEyzPg9KA=' : 'GEBGxSrIWd5TwwivevYPJJ/or4SvtLRqzfWsipG+yjOyKDWl0PU0BURgBMZVh5iV'
        const headerOptions = {
            headers: new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + tokenRest
            }
        )};

        const url = `${URLS_DEFAULT.URL_CURRENT}/SvWebGetSecurityToken?formato=json`;
        let tokenRet = this.http.get<any>(url, headerOptions)
        .pipe(
            map(token => {
               
            return token;
          }),
          catchError(this.handleError),
        );

        
        return tokenRet;

    }

    private handleError(error: any[]): Observable<any> {
      console.log('erro na requisição de generatorTokenRest => ', error);
      return throwError(error);
    }
}
